class Video {
    constructor($el) {
        this.wrapper = $el;
        this.video = $el.querySelector('video');

        if(this.video) {
            this.playBtn = $el.querySelector('button.play');
            this.soundBtn = $el.querySelector('button.sound');
            this.init();
        }
    }

    init() {
        if(this.playBtn) {
            this.playBtn.addEventListener('click', () => this.play())
        }

        if( this.soundBtn) {
            this.soundBtn.addEventListener('click', () => this.unmute())
        }
    }

    play() {
        this.wrapper.classList.add('playing')
        this.video.play();
    }

    unmute() {
        this.video.muted = false;
        this.video.controls = true;
        this.wrapper.classList.add('unmuted');

    }
}

export default wrapper => new Video(wrapper);