const tools = require('./tools');
class Slider {

    constructor(sliderComponent) {

        this.sliderComponent = sliderComponent;
        this.sliderImages = sliderComponent.querySelectorAll(".slide");
        this.sliderWrapper = sliderComponent.querySelector(".slider-area");
        this.arrowLeft = sliderComponent.querySelector("#arrow-left");
        this.arrowRight = sliderComponent.querySelector("#arrow-right");
        this.current = 0;

        if (this.sliderImages.length){
            this.init();
        }
    }

    init() {
        const slide = this.sliderImages[this.current];

        this.renderDots();
        this.updateContent(slide);
        this.registerListeners();

        //only show arrows and dots if more than 1 slide
        if (this.sliderImages.length < 2 && this.arrowLeft && this.arrowRight) {
            this.arrowLeft.style.display = 'none';
            this.arrowRight.style.display = 'none';
            document.querySelector('.slide-dots').style.opacity = 0;
        }
    }


    updateContent(slide){
        this.adjustHeightToSlide(slide);
        this.resetVideos();
        this.autoplayVideo(slide);

        this.resetDots();
        this.activateDot(slide);

    }

    adjustHeightToSlide(slide){
        // const extraAreaPx = 15;
        let newHeight = this.sliderWrapper.style.height;
        const imgEl = slide.querySelector('img.sizer');
        const videoEl = slide.querySelector('video');
        if (imgEl){
            newHeight = imgEl.height;
            imgEl.addEventListener("load", () => {
                //recalculate later if not loaded yet
                this.adjustHeightToSlide(slide);
            });
        } else if (videoEl){
            newHeight = videoEl.offsetHeight;
            videoEl.addEventListener("loadeddata", () => {
                //recalculate later if not loaded yet
                this.adjustHeightToSlide(slide);
            }, true);
        }

        //if for some reason height scaling failed, use a sane fallback
        if (newHeight < 1){
            // console.log("height scaling failed")
            newHeight = 600;
        }

        //if there is a portrait image it can get higher than the viewport, limit it to 100vh
        if (newHeight > window.innerHeight){
            newHeight = window.innerHeight;
        }

        slide.style.height = newHeight + 'px';
        this.sliderWrapper.style.height = newHeight + 'px';
        // this.sliderWrapper.style.height = newHeight + extraAreaPx + 'px';
    }

    autoplayVideo(slide){
        const video = slide.querySelector('video');
        try {
            if (video){
                video.muted = true;
                video.play();
            }
        } catch (error) {
            console.log(error);
        }
    }
    resetVideos(){
        [...this.sliderImages].forEach( slide => {
            const video = slide.querySelector('video');
            if (video){
                video.pause();
                video.currentTime = 0;
            }
        })
    }

    renderDots(){
        let dots = '';
        for (let s = 0; s < this.sliderImages.length; s++) {
            //enumerate all slides
            const slide = this.sliderImages[s];
            slide.id = `slide-${s}`;
            slide.dataset.position = s;

            //create dots
            const activeClass = (0 === s) ? 'active' : '';
            dots += `<a href="#slide-${s}" data-slide-to="#slide-${s}" class="slide-to ${activeClass}">●</a>`;
        }
        this.sliderComponent.insertAdjacentHTML("beforeend", `<div class="slide-dots no-select">${dots}</div>`);
    }
    resetDots() {
        const dots = this.sliderComponent.querySelectorAll('.slide-to');
        [...dots].forEach( dot => dot.classList.remove('active'));
    }
    activateDot(slide) {
        const dot = this.sliderComponent.querySelector(`[href='#${slide.id}']`);
        dot.classList.add('active');
    }

    scrollToSlide(slide) {
        if ('scroll' in this.sliderWrapper){
            this.sliderWrapper.scroll({ top: 0, left: slide.offsetLeft, behavior: 'smooth' });
        } else {
            this.sliderWrapper.scrollLeft = slide.offsetLeft;
        }
    }
    slideLeft() {
        if (this.current === 0) this.current = this.sliderImages.length;
        this.current--;
        this.updateContent(this.sliderImages[this.current]);
        this.scrollToSlide(this.sliderImages[this.current]);
    }
    slideRight() {
        if (this.current === this.sliderImages.length - 1) this.current = -1;
        this.current++;
        this.updateContent(this.sliderImages[this.current]);
        this.scrollToSlide(this.sliderImages[this.current]);
    }

    registerListeners(){
        //on resize
        let timeout = false;
        const delay = 250;
        window.addEventListener('resize', () => {
            // clear the timeout
            clearTimeout(timeout);
            // start timing for event "completion"
            timeout = setTimeout(this.updateContent(this.sliderImages[this.current]), delay);
        }, true);

        //on arrow click
        this.arrowLeft.addEventListener("click", () => this.slideLeft() );
        this.arrowRight.addEventListener("click", () => this.slideRight() );

        //on dot click
        const dots = this.sliderComponent.querySelectorAll('.slide-to');
        [...dots].forEach( dot => {
            dot.addEventListener('click',(e) => {
                e.preventDefault();

                const slide = document.querySelector(dot.dataset.slideTo);
                this.updateContent(slide);
                this.scrollToSlide(slide);
            });
        });

        //on Slide Viewport enter >60%
        let observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if(entry.isIntersecting) {
                    const slide = entry.target;
                    const pos = parseInt(slide.dataset.position, 10);
                    if ( pos !== this.current ){
                        this.current = pos;
                        this.updateContent(slide);
                    }
                }
            });
        }, {
            threshold: 0.6
        });
        this.sliderImages.forEach((i) => { if (i) observer.observe(i);} );
    }

}

export default Slider;
