'use strict';

const tools = require('./tools');
import Video from "./Video";
import Slider from "./Slider";
import LazyImg from "./lazy/LazyImg";
import LazyImgSlow from "./lazy/LazyImgSlow";

import hljs from 'highlight.js';
// import hljs from 'highlight.js/lib/core';
import CopyButtonPlugin from 'highlightjs-copy';
// import javascript from 'highlight.js/lib/languages/javascript';
// import html from 'highlight.js/lib/languages/vbscript-html';
// import css from 'highlight.js/lib/languages/css';

// Then register the languages you need
// hljs.registerLanguage('javascript', javascript);
// hljs.registerLanguage('html', html);
// hljs.registerLanguage('css', css);

const app = {
    init() {
        document.documentElement.classList.remove('no-js');
        tools.isMob = window.innerWidth < 949;

        app.initLazy();
        app.initSlider();
        app.mobileNav();
        app.videoAspects();
        app.videoPopups();
        app.avoidAutoplayStopVideos();
        app.imagePopups();
        app.scrollSlideIn();
        app.onResizeRedrawObjectFit();
        app.initVideos();
        app.listMarkerColorFix();
        app.codeHighlighting();
    },

    onResizeRedrawObjectFit(){
        let timeout = false;
        const delay = 250;

        const resizeFn = () => {
            document.querySelectorAll('.image img.lazy-loaded').forEach(img => {
                const objectFit = window.getComputedStyle(img).getPropertyValue('object-fit');
                if (objectFit === 'contain'){
                    img.style.objectFit='cover';
                    img.offsetHeight;
                    setTimeout(() => {
                        img.style.objectFit='';
                    }, 100);
                }
            });
        };
        window.addEventListener('resize', () => {
            // clear the timeout
            clearTimeout(timeout);
            // start timing for event "completion"
            timeout = setTimeout(resizeFn(), delay);
        }, true);
    },

    initLazy(){
        new LazyImg();
        new LazyImgSlow();
    },

    listMarkerColorFix(){
        //this is a ugly but unavoidable fix for bullet and number list pointers that do NOT
        //change their color to gray when the following text is styled like it in the ckEditor
        //with Style>gray. Since this causes li > span.gray the li::marker ist not affected by the color change
        //so we need to force it with js afterwards
        const points = document.querySelectorAll('li > .gray');
        [...points].forEach(point => {
            point.parentNode.style.color = '#727272';
        });
    },

    initSlider() {
        const sliders = document.querySelectorAll('.slider');
        if(tools.isMob) {
            for(let i = 0; i < sliders.length; i++) {
                let slide = sliders[i];
                const video = slide.querySelector('video');
                try {
                    if (video){
                        video.muted = true;
                        setTimeout(() => {
                            video.play();
                        }, 50)
                    }
                } catch (error) {
                    console.log(error);
                }

            }
        } else {
            [...sliders].forEach(slider => new Slider(slider));
        }
    },

    videoAspects(){
        //Portrait vids should adapt height depending on aspect ratio
        const vids = document.querySelectorAll('.textImage .image video');
        [...vids].forEach(vid => {
            // console.log(vid);
            // console.log(`initVid: h: ${vid.offsetHeight} / w: ${vid.offsetWidth}`);

            const resizeFn = () => {
                const vidHeight = vid.offsetHeight;
                const vidWidth = vid.offsetWidth;
                if (vidHeight > vidWidth) {
                    //if portrait limit width
                    const aspectP = vidWidth / (vidHeight / 100);

                    // console.log(vid);
                    // console.log(`h: ${vidHeight} / w: ${vidWidth} = a: ${aspectP}`);

                    vid.style.width = aspectP + '%';
                }
            }

            vid.addEventListener("playing", resizeFn);
            //since safari does not autoplay, those are working fallbacks to trigger the resize
            vid.addEventListener("loadeddata", resizeFn);
            vid.addEventListener("loadedmetadata", resizeFn);
        });
    },

    videoPopups() {
        const vids = document.querySelectorAll('.textImage .image video');
        [...vids].forEach(vid => {
            let enablePopup = true;

            //if textimage is top and not in a column the popup should be disabled since headerimage
            const notInsideColumn = (vid.closest(".columns") === null);
            const isTopImage = (vid.closest(".image-top") !== null);
            if (isTopImage && notInsideColumn){
                enablePopup = false;
            }

            if(enablePopup){
                vid.addEventListener('click', () => {

                    const popupSrc = vid.querySelector('source').src;

                    const textImage = vid.closest('.textImage');

                    const titleEl = textImage.querySelector('.texts > h2');
                    let title = (titleEl) ? titleEl.innerText : '';
                    if (vid.dataset.overlayTitle) {
                        //if custom overlay title set overwrite the title
                        title = vid.dataset.overlayTitle;
                    }

                    const subtitleEl = textImage.querySelector('.texts > .subtitle');
                    let subtitle = (subtitleEl) ? subtitleEl.innerText : '';
                    if (vid.dataset.overlaySubtitle) {
                        //if custom overlay subtitle set overwrite the subtitle
                        subtitle = vid.dataset.overlaySubtitle;
                    }

                    const textEl = textImage.querySelector('.text');
                    let text = (textEl) ? textEl.innerText : '';
                    if (vid.dataset.overlayText) {
                        //if custom overlay text set overwrite the text
                        text = vid.dataset.overlayText;

                    }

                    document.body.insertAdjacentHTML('beforebegin', `<div id="imgPopup" class="fadeIn"><div id="imgContainer"><video loop><source src="${popupSrc}" type="video/mp4">Video tag is not supported in this browser.</video><div id="imgTextWrap"><h2 id="imgTitle">${title}</h2><p id="imgSubtitle">${subtitle}</p><div id="imgText">${text}</div></div></div></div>`);

                    const imgPopup = document.getElementById('imgPopup');
                    const vidInPopup = imgPopup.querySelector('video');
                    vidInPopup.play();
                    vidInPopup.muted = false;

                    imgPopup.addEventListener('click', () => {
                        imgPopup.classList.add('fadeOut');
                        setTimeout(() => imgPopup.remove(), 150);
                    } );
                });
            } else {
                //if no Popup do auto play when in view
                vid.muted = true;
                let observer = new IntersectionObserver((entries) => {
                    entries.forEach((entry) => {
                        if (entry.intersectionRatio !== 1 && !vid.paused) {
                            vid.pause();
                        } else if (vid.paused) {
                            vid.play();
                        }
                    });
                }, { threshold: 0.7 });
                observer.observe(vid);
            }
        });
    },

    imagePopups() {
        // const imgs = document.querySelectorAll('img');
        // const imgs = document.querySelectorAll('.textImage .image img');
        const imgs = document.querySelectorAll('.textImage .image img[data-popup]');
        [...imgs].forEach(img => {
            let enablePopup = true;

            //if textimage is top and not in a column the popup should be disabled since headerimage
            const notInsideColumn = (img.closest(".columns") === null);
            const isTopImage = (img.closest(".image-top") !== null);
            if (isTopImage && notInsideColumn){
                enablePopup = false;
            }

            if(enablePopup){
                img.addEventListener('click', () => {
                    console.log("clicked img")

                    const popupSrc = img.dataset.popup;
                    const textImage = img.closest('.textImage');

                    const titleEl = textImage.querySelector('.texts > h2');
                    let title = (titleEl) ? titleEl.innerText : '';
                    if (img.dataset.overlayTitle) {
                        title = img.dataset.overlayTitle;
                    }

                    const subtitleEl = textImage.querySelector('.texts > .subtitle');
                    let subtitle = (subtitleEl) ? subtitleEl.innerText : '';
                    if (img.dataset.overlaySubtitle) {
                        subtitle = img.dataset.overlaySubtitle;
                    }

                    const textEl = textImage.querySelector('.text');
                    let text = (textEl) ? textEl.innerText : '';
                    if (img.dataset.overlayText) {
                        text = img.dataset.overlayText;
                    }

                    const bodyId = document.body.id;
                    document.body.insertAdjacentHTML('beforebegin', `<div id="imgPopup" class="fadeIn ${bodyId}"><div id="imgContainer"><img src="${popupSrc}" alt=""><div id="imgTextWrap"><h2 id="imgTitle">${title}</h2><p id="imgSubtitle">${subtitle}</p><div id="imgText">${text}</div></div></div></div>`);

                    const imgPopup = document.getElementById('imgPopup');
                    imgPopup.addEventListener('click', () => {
                        imgPopup.classList.add('fadeOut');
                        setTimeout(() => imgPopup.remove(), 150);
                    } );
                });
            }
        });
    },

    mobileNav() {
        //if login, show at once
        const menuToggle = document.querySelector("#menuToggle > input[type='checkbox']");
        const loginArea = document.querySelector("#mobileMenu .login");
        if (loginArea && menuToggle && tools.isTablet()){
            menuToggle.checked = true;
        }

        //init expanders
        const expanders = document.querySelectorAll('.navigation .expander');
        [...expanders].forEach(expander => {
            expander.addEventListener('click', () => {
                let classes = expander.parentNode.classList;
                if (classes.contains('active') || classes.contains('open')){
                    classes.remove('active');
                    classes.remove('open');
                } else {
                    classes.add('active');
                    classes.add('open');
                }
            });
        });
    },

    scrollSlideIn() {
        const slideIns = document.querySelectorAll("#main > section");

        const scrollFn = () => {
            for (let i = 0; i < slideIns.length; i++) {
                let windowHeight = window.innerHeight;
                let elementTop = slideIns[i].getBoundingClientRect().top;
                let elementVisible = 5;
                if (elementTop < windowHeight - elementVisible) {
                    slideIns[i].classList.add("slided");
                } else {
                    slideIns[i].classList.remove("slided");
                }
            }
        }


        if(slideIns) {
            if(tools.isMob) {
                for (let i = 0; i < slideIns.length; i++) {
                    slideIns[i].classList.add("slided");
                }
            } else {
                scrollFn();
                window.addEventListener("scroll", scrollFn);
            }
        }
    },

    initVideos() {
        document.querySelectorAll('.media-wrapper').forEach(Video)
    },

    avoidAutoplayStopVideos() {
        let videos = document.querySelectorAll(".textImage .image video");
        let fraction = 0.01;
        function checkScroll() {
            for(let i = 0; i < videos.length; i++) {
                let video = videos[i];
                let x = video.offsetLeft, y = video.offsetTop, w = video.offsetWidth, h = video.offsetHeight, r = x + w, //right
                    b = y + h, //bottom
                    visibleX, visibleY, visible;

                visibleX = Math.max(0, Math.min(w, window.pageXOffset + window.innerWidth - x, r - window.pageXOffset));
                visibleY = Math.max(0, Math.min(h, window.pageYOffset + window.innerHeight - y, b - window.pageYOffset));

                visible = visibleX * visibleY / (w * h);

                if (visible > fraction) {
                    video.play();
                }
                // else {
                //     video.pause();
                // }

            }

        }

        checkScroll();
        setTimeout(checkScroll, 2000)
        window.addEventListener('scroll', checkScroll, false);
        window.addEventListener('resize', checkScroll, false);
    },

    codeHighlighting() {
        hljs.addPlugin(
            new CopyButtonPlugin({
                callback: (text, el) => console.log("Copied to clipboard", text),
            })
        );
        hljs.highlightAll()
    }
};

document.addEventListener('DOMContentLoaded', () => {
    app.init();
});

window.sukoaUtils = {
    dcmadr: function (nnnn) {
        var a = "";
        for (var i = 0; i < nnnn.length; i++) {
            if (i % 3 === 0) {
                a += String.fromCharCode(nnnn.substr(i, 3));
            }
        }
        location.href = (a);
    }
};
