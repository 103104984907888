const tools = {
    isMob: false,

    each(list, fn) {
        if (list && fn) {
            for (let i = 0; i < list.length; i++) {
                fn(list[i]);
            }
        }
    },

    doOnWindowResizeList: [],
    doOnScrollEndList: [],
    doOnScrollList: [],
    windowScrolListenerInitialised: false,
    windowResizeListenerInitialised: false,

    doOnScroll(action, duringScroll, scrollEnd) {
        if (action && action.fn) {
            if (duringScroll) {
                this.doOnScrollList.push(action)
            }
            if (scrollEnd) {
                this.doOnScrollEndList.push(action);
            }
            if (!tools.windowScrolListenerInitialised) {
                tools.windowScrollListener();
            }
        }
    },

    onScrollActions(yPosition) {
        tools.each(this.doOnScrollList, action => {
            action.fn(action.arg, yPosition)
        });
    },

    onScrollEndActions(yPosition) {
        tools.each(this.doOnScrollEndList, action => {
            action.fn(action.arg, yPosition)
        });
    },

    windowScrollListener(fct) {
        let timeoutFn = null;
        window.addEventListener('scroll', (e) => {
            tools.onScrollActions(window.pageYOffset || window.scrollY);
            if (timeoutFn != null) {
                window.clearTimeout(timeoutFn);
            }
            timeoutFn = setTimeout(() => {
                tools.onScrollEndActions(window.pageYOffset || window.scrollY);
            }, 100);
        })
        tools.windowScrolListenerInitialised = true;
    },

    isTablet() {
        return window.innerWidth < 1024
    },
};

module.exports = tools;
